type ErrorPageVersion = 'notFound' | 'forbidden' | 'error';

export class FrontendLogicError extends Error {
  errorPage: ErrorPageVersion;

  constructor(message: string, errorPage: ErrorPageVersion) {
    super(message);
    this.errorPage = errorPage;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this);
    }
  }
}
