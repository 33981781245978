import { isAxiosError } from 'axios';
import {
  getConfigFromTenantAlias,
  TenantAlias,
} from '@/shared/tenants-alias-map';
import { isEmpty } from '@/shared/types/util/TypeGuards';

/**
 * Override default API client error messages here:
 */
export const customAPIErrorMessages: Record<string, string> = {
  EXISTING_SHIPPING_ACCOUNT:
    'Shipping account information already exists. Please select above',
};

export type ApiBusinessLogicError = {
  code: string;
  message: string;
  status: number;
  info?: {
    url: string;
    paymentIntentID: string;
  };
};

/**
 * When the API encounters a business logic error (trying to register with an existing email for example), it will return a JSON object with a code and message with the issue details.
 * If the API returns an error but without this structure, then it's an actual server error, not a business error.
 */
export const isApiBusinessLogicError = (
  e: unknown,
): e is ApiBusinessLogicError =>
  !!e &&
  e.hasOwnProperty('code') &&
  e.hasOwnProperty('message') &&
  e.hasOwnProperty('status');

export const getApiErrorStatus = (error: unknown) => {
  if (isApiBusinessLogicError(error)) {
    return error.status;
  }

  if (isAxiosError(error)) {
    return error.response?.status ?? 500;
  }

  return 500;
};

export const getAppIdFromQueryString = (): string | null => {
  if (typeof window === 'undefined') return null;

  const searchParams = new URLSearchParams(window.location.search);
  const tenantAlias = searchParams.get('tenantAlias');

  if (tenantAlias) {
    const config = getConfigFromTenantAlias(tenantAlias as TenantAlias);
    return config.appID.toString();
  }

  const appID = searchParams.get('appID');
  if (appID) {
    return appID.toString();
  }

  return null;
};

/**
 * @see https://stripe.com/docs/payments/3d-secure
 */
export const is3DSecureError = (e: ApiBusinessLogicError) =>
  e?.code === '3D_SECURE_ADDI_AUTHORIZATION' &&
  e?.info?.url &&
  e?.info?.paymentIntentID;

// Can be used to validate query param in situations where the value can be null or undefined when `enabled` is used.
// https://dev.to/tkdodo/react-query-and-typescript-34ai
export const assertNonNullable = <T>(
  maybeNullable: T | null | undefined,
): T => {
  if (isEmpty(maybeNullable)) {
    throw new Error(
      `Value is null or undefined, but it is expected to be non-nullable`,
    );
  }

  return maybeNullable;
};
